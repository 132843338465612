<template>
  <div class="card">
      <div class="card-header">
        <a-button class="mr-2" @click="showModalNewdemoobject">
          <template #icon ><i class="fa fa-plus"/></template>          
        </a-button>
        <a-button class="mr-2" :disabled="selectedRowKeys.length != 1" @click="editEntity(selectedRows)">
          <template #icon><i class="fa fa-pencil" /></template>
        </a-button>
        <a-button class="mr-2" :disabled="selectedRowKeys.length == 0" @click="deleteEntity(selectedRowKeys)">
          <template #icon><i class="fa fa-trash" /></template>
        </a-button>
        <a-button class="mr-2" :disabled="selectedRowKeys.length != 1" @click="downloadEntity(selectedRowKeys)">
          <template #icon><i class="fa fa-download" /></template>
        </a-button>
        <a-button class="mr-2" @click="downloadAllEntities()">
          <template #icon><i class="fa fa-download" /></template>
        </a-button>
      </div>

    <a-table
      :dataSource="gridData"
      :columns="gridColumns"
      :scroll="{ x: 'calc(700px + 100%)', y: 750 }"
      :customRow="rowClick"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      rowKey="id"
    />
  </div>
  <div>
      <a-modal width = "100" v-model:visible="visibledemoobject" title="DemoObject" @ok="handleOkdemoobject">
    <a-form ref="demoobjectForm" :model="demoobjectFormState">

      <a-form-item label="id">
        <a-input v-model:value="demoobjectFormState.id" />
      </a-form-item>
      <a-form-item label="title">
        <a-input v-model:value="demoobjectFormState.title" />
      </a-form-item>
      <a-form-item label="demoobject2">
        <a-input v-model:value="demoobjectFormState.demoObject2" />
      </a-form-item>
      <a-form-item label="text">
        <a-input v-model:value="demoobjectFormState.text" />
      </a-form-item>
      <a-form-item label="active">
        <a-input v-model:value="demoobjectFormState.active" />
      </a-form-item>
      <a-form-item label="integerquantity">
        <a-input v-model:value="demoobjectFormState.integerQuantity" />
      </a-form-item>
      <a-form-item label="decimalquantity">
        <a-input v-model:value="demoobjectFormState.decimalQuantity" />
      </a-form-item>
      <a-form-item label="date">
        <a-input v-model:value="demoobjectFormState.date" />
      </a-form-item>
      <a-form-item label="period">
        <a-input v-model:value="demoobjectFormState.period" />
      </a-form-item>
    </a-form>
  </a-modal>

    <!-- <a-modal width = "100" v-model:visible="visibledemoobject" title="DemoObject" @ok="handleOkdemoobject">            
     
        <a-form ref="demoobjectForm" :model="demoobjectFormState">

    <a-form-item label="Наименование">
      <a-select v-model:value="demoobjectFormState.demoobjectTitle" placeholder="please select your demoobjectTitle">
           <a-select-option v-for="demoobjectTitle in demoobjectTitles" 
           :key="demoobjectTitle.id" 
           v-bind:value="demoobjectTitle.id">
           {{demoobjectTitle.title}}
           </a-select-option>
      </a-select>
    </a-form-item>

        </a-form>
    </a-modal>  -->
  </div>
</template>

<script>

import axios from "axios";
import { notification } from 'ant-design-vue';

export default {

  name: 'listView',

  data() {
    return {

loading: false,

visibledemoobject: false,

demoobjectFormState: {
      id: undefined,
      title: undefined,
      demoobject2: undefined,
      text: undefined,
      active: undefined,
      integerquantity: undefined,
      decimalquantity: undefined,
      date: undefined,
      period: undefined,
    },

thisdemoobject:[],
demoobjectTitles:[],
gridColumns: [
        { dataIndex: 'id', title: 'ID'},
        { dataIndex: 'title', title: 'Title' },
        { dataIndex: 'demoObject2.title', title: 'Demoobject2' },
        { dataIndex: 'text', title: 'Text' },
        { dataIndex: 'аctive', title: 'Аctive' },
        { dataIndex: 'integerQuantity', title: 'IntegerQuantity' },
        { dataIndex: 'decimalQuantity', title: 'DecimalQuantity' },
        { dataIndex: 'date', title: 'Date' },
        { dataIndex: 'period', title: 'Period' },
        { dataIndex: 'address', title: 'Address' },
      ],
      gridData: [],
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  methods: {

    async reload() {
      this.gridData = (await axios.get("demoobject")).data;
    },
    showError(text) {
      notification.open({
        message: 'Ошибка',
        description: text,
        placement: 'bottomRight'
      });
    },
    rowClick(record, index) {
      return {

          onClick: async () => {
            console.log(record, index);

            this.thisdemoobject = record;
            this.demoobjectFormState.id = record.id;
            this.demoobjectFormState.title = record.title;
            this.demoobjectFormState.demoObject2 = record.demoObject2;
            this.demoobjectFormState.text = record.text;
            this.demoobjectFormState.аctive = record.аctive.toString();
            this.demoobjectFormState.integerQuantity = record.integerQuantity;
            this.demoobjectFormState.decimalQuantity = record.decimalQuantity;
            this.demoobjectFormState.date = record.date;
            this.demoobjectFormState.period = record.period.id;
            this.demoobjectFormState.address = record.address;

            this.showModaldemoobject ();
          }
      };
    },
    onSelectChange(selectedRowKeys, selectedRows) {      
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRows = selectedRows;
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);      
    },
    resetForm(formName){
      this.$refs[formName].resetFields();
    },
    showModaldemoobject () {
      this.visibledemoobject = true;
    },
    showModalNewdemoobject () {
          this.demoobjectFormState.id = undefined;
          this.demoobjectFormState.title = undefined;
          this.demoobjectFormState.demoObject2 = undefined;
          this.demoobjectFormState.text = undefined;
          this.demoobjectFormState.аctive = undefined;
          this.demoobjectFormState.integerQuantity = undefined;
          this.demoobjectFormState.decimalQuantity = undefined;
          this.demoobjectFormState.date = undefined;
          this.demoobjectFormState.period = undefined;
          this.demoobjectFormState.address = undefined;

          this.visibledemoobject = true;
    },
    async handleOkdemoobject () {
      console.log('demoobjectModal.');
      this.visibledemoobject = false;
      if (this.demoobjectFormState.demoobjectTitle !== undefined)
      {
        if (this.demoobjectFormState.id == undefined)
        {
          try { await axios.post("demoobject", {
            title: this.demoobjectFormState.title ,
            demoobject2: this.demoobjectFormState.demoObject2 ,
            text: this.demoobjectFormState.text ,
            active: this.demoobjectFormState.аctive.toString() ,
            integerquantity: this.demoobjectFormState.integerQuantity ,
            decimalquantity: this.demoobjectFormState.decimalQuantity ,
            date: this.demoobjectFormState.date ,
            period: this.demoobjectFormState.period,
            address: this.demoobjectFormState.address,
            });  }
          finally {
            this.demoobjectFormState.id = undefined;
            this.demoobjectFormState.title = undefined;
            this.demoobjectFormState.demoObject2 = undefined;
            this.demoobjectFormState.text = undefined;
            this.demoobjectFormState.active = undefined;
            this.demoobjectFormState.integerQuantity = undefined;
            this.demoobjectFormState.decimalQuantity = undefined;
            this.demoobjectFormState.date = undefined;
            this.demoobjectFormState.period = undefined;
            this.demoobjectFormState.address = undefined;

            await this.reload(); }
        }
        else
        {
          try { await axios.put("_demoobject", { 
            id: this.demoobjectFormState.id ,
            title: this.demoobjectFormState.title ,
            demoobject2: this.demoobjectFormState.demoObject2 ,
            text: this.demoobjectFormState.text ,
            active: this.demoobjectFormState.active.toString() ,
            integerquantity: this.demoobjectFormState.integerQuantity ,
            decimalquantity: this.demoobjectFormState.decimalQuantity ,
            date: this.demoobjectFormState.date ,
            period: this.demoobjectFormState.period,
            address: this.demoobjectFormState.address,
             }); }
          finally { 
            this.demoobjectFormState.id = undefined;
            this.demoobjectFormState.title = undefined;
            this.demoobjectFormState.demoObject2 = undefined;
            this.demoobjectFormState.text = undefined;
            this.demoobjectFormState.active = undefined;
            this.demoobjectFormState.integerQuantity = undefined;
            this.demoobjectFormState.decimalQuantity = undefined;
            this.demoobjectFormState.date = undefined;
            this.demoobjectFormState.period = undefined;
            this.demoobjectFormState.address = undefined;
            await this.reload(); }
        }
      }      
      else{return}
    },
    async deleteEntity(selectedRowKeys)
    {
      console.log(selectedRowKeys);
      try {
        await axios.delete("demoobject",
         { headers: {'Content-Type' : 'application/json; charset=utf-8'} ,
          data: selectedRowKeys}
         );
      }
      finally {
        selectedRowKeys = undefined;
        await this.reload();
      }
    },
    async editEntity(selectedRows)
    {
      console.log(selectedRows);
      this.demoobjectFormState.id = selectedRows[0].id;
      this.demoobjectFormState.title  = selectedRows[0].title.id;
      this.demoobjectFormState.demoobject2 = selectedRows[0].demoObject2.id;
      this.demoobjectFormState.text = selectedRows[0].text;
      this.demoobjectFormState.active = selectedRows[0].active;
      this.demoobjectFormState.integerquantity = selectedRows[0].integerQuantity;
      this.demoobjectFormState.decimalquantity = selectedRows[0].decimalQuantity;
      this.demoobjectFormState.date = selectedRows[0].date;
      this.demoobjectFormState.period = selectedRows[0].period.id;
      this.demoobjectFormState.address = selectedRows[0].address;

      this.showModaldemoobject ();      
    },
    async downloadEntity(selectedRowKeys)
    {
      try {
        await axios({
        url: `demoobject/download/${selectedRowKeys}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
           },
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download','Base.xlsx');
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      }
      finally {
        selectedRowKeys = undefined;
        await this.reload();
      }
    },
    async downloadAllEntities()
    {
      try {
        await axios({
        url: `demoobject/downloadall`,
        method: 'GET',
        responseType: 'blob',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
           },
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download','Base.xlsx');
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      }
      finally {
        await this.reload();
      }
    }
  },

  async created() {
    await this.reload();
  },
}
</script>
